import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useHistory } from "react-router-dom"
import { getCiotTitle } from '../utils/index.js'
import { getToken, getRefreshToken, getUserInfo } from '../utils/auths.js'
const RouterView = (props) => {
    const histoty = useHistory();
    const { routes } = props;
    useEffect(() => {
        const arrs = ['/login', '/operator-login', '/register', '/forgetpassword', '/terms', '/privacypolicy', '/404', '/login', '/privacyPolicyGerman', '/contactus']
        const pathLocation = histoty.location.pathname
        if (arrs.indexOf(pathLocation) < 0) {
            if (!(getToken() && getRefreshToken())) {
                histoty.push('/login')
            }
        }
    }, [histoty.location.pathname])
    return <Switch>
        {
            routes.map((item, index) =>
                <Route
                    key={item.path}
                    path={item.path}
                    render={(History) => {
                        document.title = item.title ? getCiotTitle(item.title.props.intlkey) : getCiotTitle('CIOTF');
                        return <item.component routes={item.children ? item.children : []} {...History}>
                            <RouterView routes={item.children ? item.children : ''}></RouterView>
                        </item.component>
                    }
                    }
                />
            )
        }
        <Redirect from='/' to={`${getToken() && getUserInfo() ? '/layout' : '/login'}`} exact />
        <Redirect from='/layout' to='/layout/dashboard/overview' exact />
        <Redirect from='/layout/product/productInfo' to='/layout/product/productInfo/myProduct' exact />
        <Redirect from='/layout/product/continueDP' to='/layout/product/continueDP/founctionDefinition' exact />
        <Redirect from='/MyAccount' to='/MyAccount/BasicInfo' exact />
        <Redirect to='/404' />
    </Switch>
}
export default RouterView
