import React, { Component } from 'react'
import { getCiotTitle } from '../utils/index.js'
export default class IntlTranslation extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }
  render() {
    return (
      <div>{getCiotTitle(this.props.intlkey)}</div>
    )
  }
}
