import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    pageLoadingVal: false
}
export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        OpenPageLoading: (state) => {
            state.pageLoadingVal = true
        },
        ClosePageLoading: (state) => {
            state.pageLoadingVal = false
        }
    }
})
export const { OpenPageLoading, ClosePageLoading } = loadingSlice.actions

export default loadingSlice.reducer
