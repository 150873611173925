import React, { lazy } from "react"
import IntlTranslation from "./intlTranslation.js";
import IconFont from '../components/icon/index.js'
const Login = lazy(() => import('../pages/login/index.js'));
const Forgetpassword = lazy(() => import('../pages/forgetpasswords/index.js'));
const NotFound = lazy(() => import('../pages/notfounds/index.js'));
const Register = lazy(() => import('../pages/register/index.js'));
const MyAccount = lazy(() => import('../pages/myaccounts/index.js'));
const BasicInfo = lazy(() => import('../pages/myaccounts/components/basicinfos/index.js'));
const Security = lazy(() => import('../pages/myaccounts/components/security/index.js'));
const AmendEmail = lazy(() => import('../pages/myaccounts/components/bindemail/index.js'))
const AmendPhone = lazy(() => import('../pages/myaccounts/components/bindphone/index.js'))
const UnbindEmail = lazy(() => import('../pages/myaccounts/components/unbindemails/index.js'))
const UnbindPhone = lazy(() => import('../pages/myaccounts/components/unbindphones/index.js'))
const Dashboard = lazy(() => import('../pages/dashboard/index'));
const Device = lazy(() => import('../pages/device/devices/index.js'));
const DeviceDetails = lazy(() => import('../pages/device/devicedetails/index.js'));
const DeviceLog = lazy(() => import('../pages/device/devicelog/index.js'));
const DeviceFault = lazy(() => import('../pages/device/devicefault/index.js'));
const MessageMonitoring = lazy(() => import('../pages/device/messagemonitoring/index.js'));
const AppGrade = lazy(() => import('../pages/operation/consumer/grade/index.js'));
const AppGradeAdd = lazy(() => import('../pages/operation/consumer/grade/component/add/index.js'));
const AppUser = lazy(() => import('../pages/operation/consumer/user/index.js'));
const AppUserDevice = lazy(() => import('../pages/operation/consumer/user/component/userdevice/index.js'));
const AppAuthority = lazy(() => import('../pages/operation/consumer/authority/index.js'));
const WebUser = lazy(() => import('../pages/operation/business/user/index.js'));
const ParentCategory = lazy(() => import('../pages/product/categoryparenti/index.js'));
const ChildCategory = lazy(() => import('../pages/product/categorychildi/index.js'));
const WebRole = lazy(() => import('../pages/operation/business/role/index.js'));
const Roleadd = lazy(() => import('../pages/operation/business/role/component/roleadd/index.js'));
const WebAuthority = lazy(() => import('../pages/operation/business/authority/index.js'));
const Layout = lazy(() => import('../components/layout/index.js'));
const ProductInfo = lazy(() => import('../pages/product/productInfo/index.js'));
const ProductInfos = lazy(() => import('../pages/product/productInfo/component/proinfo/index.js'));
const MyProduct = lazy(() => import('../pages/product/productInfo/component/myproduct/index.js'));
const AuthorityProduct = lazy(() => import('../pages/product/productInfo/component/authorityproduct/index.js'));
const ProductAuthorizeRecord = lazy(() => import('../pages/product/productauthorizerecordi/index.js'));
const ProductAdd = lazy(() => import('../pages/product/productInfo/component/productadd/index.js'));
const ProductProduction = lazy(() => import('../pages/product/production/index.js'));
const ProductEdit = lazy(() => import('../pages/product/productInfo/component/productedit/index.js'));
const ContinueDP = lazy(() => import('../pages/product/productInfo/component/continuedp/index.js'));
const FounctionDefinition = lazy(() => import('../pages/product/productInfo/component/founctiondefinition/index.js'));
const DeviceInteraction = lazy(() => import('../pages/product/productInfo/component/deviceinteraction/index.js'));
const HardwareDevelopment = lazy(() => import('../pages/product/productInfo/component/hardwaredevelopment/index.js'));
const TermsOfUse = lazy(() => import('../pages/termsofuse/index.js'));
const PrivacyPolicy = lazy(() => import('../pages/privacypolicy/index.js'));
const Maps = lazy(() => import('../pages/map/index.js'));
const LoginMyId = lazy(() => import('../pages/loginhenkelmyid/index'));
const FirmWareManagement = lazy(() => import('../pages/device/firmwaremanagement/index.js'));
const FirmwareDetail = lazy(() => import('../pages/device/firmwaremanagement/component/firmwaredetial/index.js'));
const FirmwareUpdate = lazy(() => import('../pages/device/firmwareupdate/index.js'));
const ReleaseVersion = lazy(() => import('../pages/device/firmwareupdate/component/releaseversion/index.js'));
const PrivacyPolicyGerman = lazy(() => import('../pages/privacypolicygerman/index.js'));
const ContactUs = lazy(() => import('../pages/contact/index.js'));
const routes = [
    {
        path: '/operator-login',
        component: Login
    },
    {
        path: '/login',
        component: LoginMyId
    },
    {
        path: '/privacyPolicyGerman',
        component: PrivacyPolicyGerman
    },
    {
        path: '/contactus',
        component: ContactUs
    },
    {
        path: '/register',
        component: Register,
    },
    {
        path: '/layout/dashboard/monitor',
        component: Maps,
        title: <IntlTranslation intlkey="monitorCenter" />,
    },
    {
        path: '/forgetpassword',
        component: Forgetpassword
    },
    {
        path: '/terms',
        component: TermsOfUse,
    },
    {
        path: '/privacypolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/MyAccount',
        component: MyAccount,
        children: [
            {
                path: '/MyAccount/BasicInfo',
                component: BasicInfo,
            },
            {
                path: '/MyAccount/Security',
                component: Security,
            },
            {
                path: '/MyAccount/amendEmail',
                component: AmendEmail,
            },
            {
                path: '/MyAccount/amendPhone',
                component: AmendPhone,
            },
            {
                path: '/MyAccount/unbindPhone',
                component: UnbindPhone,
            },
            {
                path: '/MyAccount/unbindEmail',
                component: UnbindEmail,
            },
        ]
    },
    {
        path: '/layout',
        component: Layout,
        title: <IntlTranslation intlkey="overView" />,
        children: [
            {
                path: '/layout/dashboard/overview',
                component: Dashboard,
                title: <IntlTranslation intlkey="home" />,
            },
            {
                path: '/layout/dashboard',
                title: <IntlTranslation intlkey="overView" />,
                icon: <IconFont type="icon-shouye" />,
                istop: true,
                itemKeys: 0,
                children: [
                    {
                        path: '/layout/dashboard/dashboard',
                        title: <IntlTranslation intlkey="overView" />,
                        children: [
                            {
                                path: '/layout/dashboard/overview',
                                component: Dashboard,
                                title: <IntlTranslation intlkey="home" />,
                            },
                            {
                                path: '/layout/dashboard/monitor',
                                component: Maps,
                                authority: ['MONITOR_CENTER_READ'],
                                title: <IntlTranslation intlkey="monitorCenter" />,
                            }
                        ]
                    }
                ]
            },
            {
                path: '/layout/product/parentCategoryParent',
                component: ParentCategory,
                title: <IntlTranslation intlkey="parentCategory" />,
            },
            {
                path: '/layout/product/parentCategoryChild',
                title: <IntlTranslation intlkey="childCategory" />,
                component: ChildCategory
            },
            {
                path: '/layout/product/productAdd',
                title: <IntlTranslation intlkey="productInfo" />,
                component: ProductAdd
            },
            {
                path: '/layout/product/productEdit',
                title: <IntlTranslation intlkey="productEdit" />,
                component: ProductEdit
            },
            {
                path: '/layout/product/continueDP',
                title: <IntlTranslation intlkey="continueDP" />,
                component: ContinueDP,
                children: [
                    {
                        path: '/layout/product/continueDP/founctionDefinition',
                        title: <IntlTranslation intlkey="continueDevelop" />,
                        component: FounctionDefinition
                    },
                    {
                        path: '/layout/product/continueDP/deviceInteraction',
                        title: <IntlTranslation intlkey="continueDevelop" />,
                        component: DeviceInteraction
                    },
                    {
                        path: '/layout/product/continueDP/hardwaredevelopment',
                        title: <IntlTranslation intlkey="continueDevelop" />,
                        component: HardwareDevelopment
                    },
                ]
            },
            {
                path: '/layout/product/productInfo',
                title: <IntlTranslation intlkey="productInfo" />,
                component: ProductInfo,
                children: [
                    {
                        path: '/layout/product/productInfo/myProduct',
                        title: <IntlTranslation intlkey="productInfo" />,
                        component: MyProduct
                    },
                    {
                        path: '/layout/product/productInfo/authorityProduct',
                        title: <IntlTranslation intlkey="productInfo" />,
                        component: AuthorityProduct
                    },
                ]
            },
            {
                path: '/layout/product/productInfos',
                title: <IntlTranslation intlkey="productInfos" />,
                component: ProductInfos
            },
            {
                path: '/layout/product/productauthorizerecordi',
                title: <IntlTranslation intlkey="authorizeRecord" />,
                component: ProductAuthorizeRecord
            },
            {
                path: '/layout/product/production',
                title: <IntlTranslation intlkey="production" />,
                component: ProductProduction
            },
            {
                path: '/layout/product',
                title: <IntlTranslation intlkey="product" />,
                icon: <IconFont type="icon-chanpin" />,
                istop: true,
                itemKeys: 1,
                children: [
                    {
                        path: '/layout/product/product',
                        title: <IntlTranslation intlkey="product" />,
                        authority: 'PRODUCT',
                        children: [
                            {
                                path: '/layout/product/productInfo',
                                authority: ['PRODUCT_READ', "PRODUCT_WRITE"],
                                title: <IntlTranslation intlkey="productInfo" />,
                                component: ProductInfo,
                            },
                            {
                                path: '/layout/product/productauthorizerecordi',
                                authority: ['PRODUCT_AUTHORIZE_READ', "PRODUCT_AUTHORIZE_WRITE"],
                                title: <IntlTranslation intlkey="authorizeRecord" />,
                                component: ProductAuthorizeRecord
                            },
                            {
                                path: '/layout/product/production',
                                authority: ['PRODUCT_PRODUCTION_READ', "PRODUCT_PRODUCTION_WRITE"],
                                title: <IntlTranslation intlkey="production" />,
                                component: ProductProduction
                            },

                        ]
                    },
                    {
                        path: '/layout/product/productcategory',
                        title: <IntlTranslation intlkey="productCategory" />,
                        authority: 'PRODUCT_category',
                        children: [
                            {
                                path: '/layout/product/parentCategoryParent',
                                authority: ['PRODUCT_CATEGORY_PARENT_READ', "PRODUCT_CATEGORY_PARENT_WRITE"],
                                title: <IntlTranslation intlkey="parentCategory" />,
                                component: ParentCategory
                            },
                            {
                                path: '/layout/product/parentCategoryChild',
                                authority: ['PRODUCT_CATEGORY_CHILD_READ', "PRODUCT_CATEGORY_CHILD_WRITE"],
                                title: <IntlTranslation intlkey="childCategory" />,
                                component: ChildCategory
                            },
                        ]
                    }

                ]
            },
            {
                path: '/layout/device/devicelog',
                component: DeviceLog,
                title: <IntlTranslation intlkey="deviceLog" />,
            },
            {
                path: '/layout/device/deviceList',
                component: Device,
                title: <IntlTranslation intlkey="device" />
            },
            {
                path: '/layout/device/details',
                component: DeviceDetails,
                title: <IntlTranslation intlkey="deviceDetails" />,
            },
            {
                path: '/layout/device/fault',
                component: DeviceFault,
                title: <IntlTranslation intlkey="deviceFault" />,
            },
            {
                path: '/layout/device/messagemonitoring',
                component: MessageMonitoring,
                title: <IntlTranslation intlkey="messageMonitoring" />,
            },
            {
                path: '/layout/device/firmwareManagement',
                component: FirmWareManagement,
                title: <IntlTranslation intlkey="firmWareManagement" />,
            },
            {
                path: '/layout/device/firmwareDetail',
                component: FirmwareDetail,
                title: <IntlTranslation intlkey="firmwareDetail" />,
            },
            {
                path: '/layout/device/releaseVersion',
                component: ReleaseVersion,
                title: <IntlTranslation intlkey="releaseVersion" />,
            },
            {
                path: '/layout/device/firmwareUpdate',
                component: FirmwareUpdate,
                title: <IntlTranslation intlkey="firmwareUpdate" />,
            },
            {
                path: '/layout/device',
                title: <IntlTranslation intlkey="device" />,
                icon: <IconFont type="icon-chanpin1" />,
                istop: true,
                itemKeys: 2,
                children: [
                    {
                        path: '/layout/device/device',
                        title: <IntlTranslation intlkey="device" />,
                        children: [
                            {
                                path: '/layout/device/deviceList',
                                authority: ['DEVICE_READ'],
                                component: Device,
                                title: <IntlTranslation intlkey="deviceManage" />,
                            },
                            {
                                path: '/layout/device/devicelog',
                                authority: ['DEVICE_LOG_READ'],
                                component: DeviceLog,
                                title: <IntlTranslation intlkey="deviceLog" />,
                            },
                            {
                                path: '/layout/device/fault',
                                component: DeviceFault,
                                authority: ['DEVICE_ERROR_READ'],
                                title: <IntlTranslation intlkey="deviceFault" />,
                            },
                            {
                                path: '/layout/device/messagemonitoring',
                                authority: ['PUSH_RECORD_READ'],
                                component: MessageMonitoring,
                                title: <IntlTranslation intlkey="messageMonitoring" />,
                            },
                            {
                                path: '/layout/device/firmwareManagement',
                                authority: ['FIRMWARE_READ', "FIRMWARE_WRITE"],
                                component: FirmWareManagement,
                                title: <IntlTranslation intlkey="firmWareManagement" />,
                            },
                            {
                                path: '/layout/device/firmwareUpdate',
                                authority: ["FIRMWARE_VERSION_UPGRADE_READ", 'FIRMWARE_VERSION_UPGRADE_WRITE'],
                                component: FirmwareUpdate,
                                title: <IntlTranslation intlkey="firmwareUpdate" />,
                            }

                        ]
                    }
                ]
            },
            {
                path: '/layout/operation/app/user',
                component: AppUser,
                title: <IntlTranslation intlkey="userManagement" />,
            },
            {
                path: '/layout/operation/user/userdevice',
                component: AppUserDevice,
                title: <IntlTranslation intlkey="userQueryDevice" />,
            },
            {
                path: '/layout/operation/app/grade',
                component: AppGrade,
                title: <IntlTranslation intlkey="gradeManagement" />,
            },
            {
                path: '/layout/operation/grade/add',
                component: AppGradeAdd,
                title: <IntlTranslation intlkey="gradeManagement" />,
            },
            {
                path: '/layout/operation/app/authority',
                component: AppAuthority,
                title: <IntlTranslation intlkey="authorityManagement" />,
            },
            {
                path: '/layout/operation/web/user',
                component: WebUser,
                title: <IntlTranslation intlkey="userManagement" />,
            },
            {
                path: '/layout/operation/web/role',
                component: WebRole,
                title: <IntlTranslation intlkey="roleManagement" />,
            },
            {
                path: '/layout/operation/role/add',
                component: Roleadd,
                title: <IntlTranslation intlkey="roleManagement" />,
            },
            {
                path: '/layout/operation/web/authority',
                component: WebAuthority,
                title: <IntlTranslation intlkey="authorityManagement" />,
            },
            {
                path: '/layout/operation',
                title: <IntlTranslation intlkey="operation" />,
                icon: <IconFont type="icon-a-shujufenxiqushi-03" />,
                istop: true,
                itemKeys: 3,
                children: [
                    {
                        path: '/layout/operation/app',
                        title: <IntlTranslation intlkey="appOperation" />,
                        children: [
                            {
                                path: '/layout/operation/app/user',
                                authority: ["USER_CONSUMER_READ", "USER_CONSUMER_WRITE"],
                                component: AppUser,
                                title: <IntlTranslation intlkey="userManagement" />,
                            },
                            {
                                path: '/layout/operation/app/grade',
                                authority: ["GRADE_READ", "GRADE_WRITE"],
                                component: AppGrade,
                                title: <IntlTranslation intlkey="gradeManagement" />
                            },
                            {
                                path: '/layout/operation/app/authority',
                                authority: ["CONSUMER_AUTHORITY_READ", "CONSUMER_AUTHORITY_WRITE"],
                                component: AppAuthority,
                                title: <IntlTranslation intlkey="authorityManagement" />,
                            }
                        ]
                    },
                    {
                        path: '/layout/operation/web',
                        title: <IntlTranslation intlkey="webOperation" />,
                        children: [
                            {
                                path: '/layout/operation/web/user',
                                authority: ['USER_BUSINESS_READ', "USER_BUSINESS_WRITE"],
                                component: WebUser,
                                title: <IntlTranslation intlkey="userManagement" />
                            },
                            {
                                path: '/layout/operation/web/role',
                                authority: ['ROLE_READ', "ROLE_WRITE"],
                                component: WebRole,
                                title: <IntlTranslation intlkey="roleManagement" />
                            },
                            {
                                path: '/layout/operation/web/authority',
                                authority: ['BUSINESS_AUTHORITY_READ', "BUSINESS_AUTHORITY_WRITE"],
                                component: WebAuthority,
                                title: <IntlTranslation intlkey="authorityManagement" />
                            }
                        ]
                    },
                ]
            },

        ]
    },
    {
        path: '/404',
        component: NotFound,
    }
]
export default routes
