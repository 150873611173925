import Cookies from 'js-cookie'

const TokenKey = 'access_token'
const refreshKey = 'refresh_token'
const userKey = 'userinfo'

export const getToken = () => {
  return Cookies.get(TokenKey)
}

export const getRefreshToken = () => {
  return Cookies.get(refreshKey)
}

export const setToken = (token) => {
  return Cookies.set(TokenKey, token)
}
export const setRefreshToken = (token) => {
  return Cookies.set(refreshKey, token)
}

export const removeToken = () => {
  return Cookies.remove(TokenKey)
}
export const removeRefreshToken = () => {
  return Cookies.remove(refreshKey)
}

export const setUserInfos = (userinfo) => {
  return localStorage.setItem(userKey, JSON.stringify(userinfo))
}

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(userKey))
}

export const removeUserInfo = () => {
  localStorage.removeItem(userKey)
}
