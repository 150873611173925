
import jwt from 'jwt-decode'
import intl from 'react-intl-universal'
export const token_decode = (data) => {
  return jwt(data)
}
export const timestampToTime = (timestamp) => {
  const date = new Date(timestamp);
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

  const strDate = Y + M + D + h + m + s;
  return strDate;
}
export const getCiotTitle = (data, value) => {
  return intl.get(data, value)
}