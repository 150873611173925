import React, { Component } from 'react'
import routes from './router/config.js'
import RouteView from "./router/index";
import intl from 'react-intl-universal';
import store from "./store/index.js"
const locales = {
  "en": require('./locales/en-US.json'),
  "zh": require('./locales/zh-CN.json'),
};

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initDone: false,
      loading: false
    }
  }
  componentWillMount() {
    store.subscribe(() => {
      const storeState = store.getState()
      this.setState({
        loading: storeState.loading.pageLoadingVal
      })
    })



    const lang = (navigator.language || navigator.browserLanguage).toLowerCase();
    if (lang.indexOf('zh') >= 0) {
      localStorage.setItem("defaultLng", "zh")
    } else {
      localStorage.setItem("defaultLng", "en")
    }
    this.loadLocales();


  }

  loadLocales() {
    intl.init({
      currentLocale: localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh',
      locales,
    })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  render() {
    return (
      this.state.initDone &&
      <RouteView routes={routes}>
      </RouteView>
    )
  }

}

