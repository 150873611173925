import { configureStore } from '@reduxjs/toolkit'
import loadingReducer from './loading/index'
import userReducer from './user/index'
import lockSliceReducer from './loginLock/index'
const store = configureStore({
    reducer: {
        loading: loadingReducer,
        user: userReducer,
        loginLock: lockSliceReducer
    },
})
export default store