import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    loginLock: false,
    lockTime: 0
}
export const lockSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        OpenPageLock: (state) => {
            state.loginLock = true
        },
        ClosePageLock: (state) => {
            state.loginLock = false
        },
        SetLockTime: (state, action) => {
            state.lockTime = action.payload
        }
    }
})
export const { OpenPageLock, ClosePageLock, SetLockTime } = lockSlice.actions

export default lockSlice.reducer
