import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "../../utils/auths";
const initialState = {
    name: "",
    role: "",
    avatar: "",
    token: getToken(),
    client_id: '',
    authorities: null,
    exp: null,
    jti: null
};
export const userSlice = createSlice({
    name: 'uesr',
    initialState,
    reducers: {
        setUserToken: (state, action) => {
            state.token = action.payload
        },
        setUserInfo: (state, action) => {
            state.name = action.user_name
            state.client_id = action.client_id
            state.exp = action.exp
            state.jti = action.jti
            state.authorities = action.authorities
        },
        resetUser: (state) => {
            state.name = ""
            state.role = ""
            state.avatar = ""
            state.token = getToken()
            state.client_id = ''
            state.authorities = null
            state.exp = null
            state.jti = null
        }
    }
})
export const { setUserToken, setUserInfo, resetUser } = userSlice.actions
export default userSlice.reducer

